import React, {Component} from 'react';
import App from "../App";

class AppWrapper extends Component {
	
	render() {
		return <App/>;
	}
}

export default AppWrapper;